@import "_variables";  


a.contrast-high {
    display: none;
}

.ada {color:#000; background: #fff;}

/*Banner*/
.ccms-banner-overlay {
	a.ccms-banner-button, a.ccms-banner-button:link, a.ccms-banner-button:visited {
	    background-color: @color-primary;
	    color: contrast(@color-primary, #111111, #ffffff);
	}
}

.sidenav ul{background: #ffffff;}